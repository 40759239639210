import React from 'react';
import styled from 'styled-components';

//import Columns from 'react-bulma-components/lib/components/columns';

export default () => {
	return (
		<Wrap>
			<div className="columns is-gapless is-multiline">
				<div className="column is-12-tablet is-5-desktop is-4-widescreen">
					<Left>
						<ul>
							<li>
								<a href="/privacy">PRIVACY POLICY</a>
							</li>
							<li>
								<a href="/contact">LOCATIONS</a>
							</li>
							<li>
								<a
									target="_blank"
									rel="noopener noreferrer"
									href="https://www.spergel.ca/"
								>
									PERSONAL DEBT HELP
								</a>
							</li>
						</ul>
					</Left>
				</div>
				<div className="column is-12-tablet is-4-desktop is-4-widescreen">
					<Center>
						<a href="/">
							<img
								src="/img/Spergel-Logo-01.svg"
								alt="Spergel Corporate Logo"
							/>
						</a>
					</Center>
				</div>
				<div className="column is-12-tablet is-3-desktop is-4-widescreen">
					<Right>
						<img
							src="/img/SpergelCorporate_About_GripLogo_Colour.svg"
							alt="GRIP Logo"
						/>
					</Right>
				</div>
			</div>
		</Wrap>
	);
};

const Wrap = styled.footer`
	padding: 1rem;
	padding-bottom: 6rem;
	@media only screen and (min-width: 1088px) {
		padding: 0 4rem;
	}
`;

const Left = styled.div`
	display: flex;
	align-items: center;
	height: 100%;
	ul {
		padding-left: 0;
		margin: 0;
		width: 100%;
		li {
			display: block;
			text-align: center;
			padding: 0.5rem;
			&:last-child {
				a {
					margin-right: 0;
				}
			}
			a {
				margin-right: 0;
				color: inherit;
				text-decoration: none;
				font-size: 0.85rem;
				font-weight: 700;
			}
		}
		@media only screen and (min-width: 768px) {
			width: 100%;
		}

		@media only screen and (min-width: 1088px) {
			width: 100%;
			li {
				display: inline;
			}
			a {
				margin-right: 1.5rem;
			}
		}
	}
`;
const Center = styled.div`
	text-align: center;
	padding: 1.5rem;
	img {
		height: 3rem;
	}
`;
const Right = styled.div`
	display: flex;
	align-items: center;
	height: 100%;
	padding-left: 0;
	justify-content: center;
	@media only screen and (min-width: 1088px) {
		justify-content: flex-start;
		padding-left: 4rem;
	}
	img {
		height: 3rem;
	}
`;
