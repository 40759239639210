import React from 'react';

import styled from 'styled-components';
import common from '../styles/common';

//import Container from 'react-bulma-components/lib/components/container';

const Wrap = styled.div`
	background-color: ${common.spergelRed};

	h3 {
		line-height: 1.2;
		color: white;
		font-weight: 200;
		font-size: 2rem;
	}
	a {
		font-weight: 700;
		color: white;
		text-decoration: none;
		border-bottom: 1px solid ${common.lightSpergelRed};
		padding-bottom: 0.125rem;
	}
`;

const Inner = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 160px;
	text-align: center;
	padding: 1rem;
`;

export default ({ children }) => {
	return (
		<Wrap>
			<div className="container">
				<Inner>{children}</Inner>
			</div>
		</Wrap>
	);
};
