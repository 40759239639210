import React from 'react';
import styled from 'styled-components';

//import Columns from 'react-bulma-components/lib/components/columns';

export default () => {
	return (
		<Wrap>
			<div className="columns is-gapless is-multiline">
				<div className="column is-12">
					<Center>
						<a href="/">
							<img
								src="/img/Spergel-Logo-01.svg"
								alt="Spergel Corporate Logo"
							/>
						</a>
					</Center>
				</div>
			</div>
		</Wrap>
	);
};

const Wrap = styled.footer`
	padding: 1rem;
	@media only screen and (min-width: 1088px) {
		padding: 0 4rem;
	}
`;

const Center = styled.div`
	text-align: center;
	padding: 1.5rem;
	img {
		height: 3rem;

		@media (max-width: 420px) {
			height: 2rem;
		}
	}
`;
