import React, { useState } from 'react';
import styled from 'styled-components';

export default () => {
	const [isShown, setIsShown] = useState(false);

	return (
		<Wrap>
			<Logo>
				<a href="/">
					<img src="/img/Spergel-Logo-01.svg" alt="Spergel Corporate Logo" />
				</a>
			</Logo>
			<Links>
				<TopLinks>
					<li>
						<a href="/insights">INSIGHTS</a>
					</li>
					<li>
						<a href="/assets">ASSETS</a>
					</li>
					<li>
						<a href="/engagements">ENGAGEMENTS</a>
					</li>
				</TopLinks>
				<BottomLinks>
					<li>
						<a href="/services">SERVICES</a>
					</li>
					<li>
						<a href="/industries">INDUSTRIES</a>
					</li>
					<li>
						<a href="/team">OUR TEAM</a>
					</li>
					<li>
						<a href="/about">ABOUT US</a>
					</li>
					<li>
						<a href="/contact">CONTACT</a>
					</li>
				</BottomLinks>
			</Links>
			<Hamburger>
				<img
					src={isShown ? '/img/close.svg' : '/img/ham.svg'}
					alt="Hamburger"
					onClick={() => setIsShown(!isShown)}
				/>
				<MobileMenu isShown={isShown}>
					<li>
						<a href="/">HOME</a>
					</li>
					<li>
						<a href="/services">SERVICES</a>
					</li>
					<li>
						<a href="/industries">INDUSTRIES</a>
					</li>
					<li>
						<a href="/team">OUR TEAM</a>
					</li>
					<li>
						<a href="/about">ABOUT US</a>
					</li>
					<li>
						<a href="/contact">CONTACT</a>
					</li>
					<li>
						<a href="/insights">INSIGHTS</a>
					</li>
					<li>
						<a href="/assets">ASSETS</a>
					</li>
					<li>
						<a href="/engagements">ENGAGEMENTS</a>
					</li>
				</MobileMenu>
			</Hamburger>
		</Wrap>
	);
};

const Wrap = styled.nav`
	display: flex;
	padding: 0 1rem;
	@media only screen and (min-width: 900px) {
		padding: 0 4rem;
	}
	position: fixed;
	width: 100%;
	background-color: white;
	z-index: 100;
	justify-content: space-between;
`;

const Logo = styled.div`
	img {
		height: 4rem;
		width: auto;
		display: block;
		padding: 1rem 0;
		@media only screen and (min-width: 768px) {
			height: 6rem;
			padding: 1.75rem 0;
		}
	}
`;

const Links = styled.div`
	flex-grow: 1;
	display: none;
	flex-direction: column;
	padding: 1rem 0 1.5rem 0;
	justify-content: space-between;
	@media only screen and (min-width: 768px) {
		display: flex;
	}
`;

const TopLinks = styled.ul`
	padding-left: 0;
	margin: 0;
	text-align: right;
	li {
		display: inline;
		font-size: 0.75rem;
		margin-right: 2rem;
		a {
			color: inherit;
			text-decoration: none;
		}
		&:last-child {
			margin-right: 0;
		}
	}
`;
const BottomLinks = styled.ul`
	padding-left: 0;
	margin: 0;
	text-align: right;
	li {
		display: inline;
		font-size: 1rem;
		font-weight: 700;
		margin-right: 1rem;
		@media only screen and (min-width: 1000px) {
			margin-right: 1rem;
		}
		a {
			color: inherit;
			text-decoration: none;
		}
		&:last-child {
			margin-right: 0;
		}
	}
`;
const Hamburger = styled.div`
	position: relative;
	img {
		height: 4rem;
		width: auto;
		display: block;
		padding: 1.25rem 0;
		@media only screen and (min-width: 768px) {
			display: none;
		}
	}
`;

const MobileMenu = styled.ul`
	display: ${(p) => (p.isShown ? 'block' : 'none')};
	@media only screen and (min-width: 768px) {
		display: none;
	}
	position: absolute;
	top: 4rem;
	right: -1rem;
	width: 100vw;
	background-color: #f2f2f2;
	margin: 0;
	opacity: 0.95;
	padding: 1.6rem 0;
	li {
		list-style-type: none;
		text-align: center;
		font-weight: 700;
		a {
			color: inherit;
			text-decoration: none;
			padding: 0.5rem 1rem;
			display: block;
		}
	}
`;
