import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import '../styles/main.scss';
import { createGlobalStyle } from 'styled-components';

import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

import GoogleAdsNavbar from '../components/google-ads-page/GoogleAdsNavbar';
import GoogleAdsFooter from '../components/google-ads-page/GoogleAdsFooter';

import common from '../styles/common';

const GlobalStyle = createGlobalStyle`
	@import url('https://fonts.googleapis.com/css?family=Merriweather:300,300i,700|Lato:400,700');
	body {
    font-size: 16px;
		font-family: 'Lato', sans-serif;
		padding: 0;
		margin: 0;
		color: ${common.slate};
		* {
			box-sizing: border-box;
		}
		p {
			line-height: 1.7;
			font-family: 'Merriweather', serif;
			font-weight: 300;
		}
	}
	
	.visually-hidden {
		position: absolute !important;
		clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
		clip: rect(1px, 1px, 1px, 1px);
		padding:0 !important;
		border:0 !important;
		height: 1px !important; 
		width: 1px !important; 
		overflow: hidden;
	}
`;

const Layout = ({ children, page }) => {
	return (
		<Fragment>
			<GlobalStyle />
			<Helmet>
				<html lang="en" />
				<title>Spergel Corporate</title>
				<meta
					name="description"
					content="Get back to running your business.
Let us solve your financial problems."
				/>
				<link
					rel="apple-touch-icon"
					sizes="180x180"
					href="/apple-touch-icon.png"
				/>
				<link
					rel="icon"
					type="image/png"
					sizes="32x32"
					href="/favicon-32x32.png"
				/>
				<link
					rel="icon"
					type="image/png"
					sizes="16x16"
					href="/favicon-16x16.png"
				/>
				<link rel="manifest" href="/site.webmanifest" />
				<link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
				<meta name="msapplication-TileColor" content="#b91d47" />
				<meta name="theme-color" content="#ffffff" />
			</Helmet>
			{page === 'googleAds' ? <GoogleAdsNavbar /> : <Navbar />}
			{children}
			{page === 'googleAds' ? <GoogleAdsFooter /> : <Footer />}
		</Fragment>
	);
};

export default Layout;
