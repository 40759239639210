import React from 'react';
import styled from 'styled-components';

import common from '../styles/common';

export default ({ children, href }) => {
	return (
		<Wrap>
			<a href={href}>{children} →</a>
		</Wrap>
	);
};

const Wrap = styled.div`
	a {
		margin-left: 0.5rem;
		text-decoration: none;
		color: ${common.spergelRed};
		font-size: 1rem;
		font-weight: 700;
	}
`;
