import styled from 'styled-components';

export default {
	baseFont: `'Lato', sans-serif`,
	spergelRed: '#CA3C4C',
	lightSpergelRed: '#E85567',
	lightGrey: '#B7BCC2',
	slate: '#3B4254',
	blue: '#617897',
	devices: {
		tablet: '(min-width: 768px)',
		desktop: '(min-width: 1088px)',
		wide: '(min-width: 1472px)',
	},
};

export const PageWrap = styled.div`
	padding-top: 4rem;
	@media only screen and (min-width: 768px) {
		padding-top: 6rem;
	}
`;
