import React from 'react';
import styled from 'styled-components';

//import Container from 'react-bulma-components/lib/components/container';

const Wrap = styled.div`
	background-image: url(${(p) => (p.mobile ? p.mobile : p.image)});
	@media only screen and (min-width: 768px) {
		background-image: url(${(p) => p.image});
	}
	background-size: cover;
	background-position: center center;
	height: ${(p) => p.height || 400}px;
	padding: 0;
	display: flex;
	align-items: center;
`;

const Title = styled.h2`
	font-size: 2rem;
	@media only screen and (min-width: 768px) {
		font-size: 3rem;
	}

	margin: 0;
	font-weight: ${(p) => (p.blog === true ? 700 : 400)};
	color: ${(p) => (p.blog === true ? 'white' : '')};
`;

const Sub = styled.h3`
	font-size: 1rem;
	@media only screen and (min-width: 768px) {
		font-size: 1.5rem;
	}
	margin: 0;
	font-weight: 400;
	color: ${(p) => (p.blog === true ? 'white' : '')};
`;

export default ({ image, title, height, sub, blog, mobile }) => (
	<Wrap image={image} height={height} mobile={mobile}>
		<div className="container" style={{ padding: '0 2rem' }}>
			<Title blog={blog}>{title}</Title>
			{sub && <Sub blog={blog}>Posted on {sub}</Sub>}
		</div>
	</Wrap>
);
