import React from 'react';
import styled from 'styled-components';

import common from '../../styles/common';

export default () => {
	return (
		<Wrap>
			<Logo>
				<a href="/">
					<img src="/img/Spergel-Logo-01.svg" alt="Spergel Corporate Logo" />
				</a>
			</Logo>
			<Links>
				<p className="desktop">
					Call today: <a href="tel:+18775014321">877-501-4321</a>
				</p>
				<a className="mobile" href="tel:+18775014321">
				<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M18.48 22.926l-1.193.658c-6.979 3.621-19.082-17.494-12.279-21.484l1.145-.637 3.714 6.467-1.139.632c-2.067 1.245 2.76 9.707 4.879 8.545l1.162-.642 3.711 6.461zm-9.808-22.926l-1.68.975 3.714 6.466 1.681-.975-3.715-6.466zm8.613 14.997l-1.68.975 3.714 6.467 1.681-.975-3.715-6.467z"/></svg>
					<span className="visually-hidden">Call today: 877-501-4321</span>
				</a>
			</Links>
		</Wrap>
	);
};

const Wrap = styled.nav`
	display: flex;
	padding: 0 1rem;
	@media only screen and (min-width: 900px) {
		padding: 0 4rem;
	}
	position: fixed;
	width: 100%;
	background-color: white;
	z-index: 100;
	justify-content: space-between;
`;

const Logo = styled.div`
	img {
		height: 4rem;
		width: auto;
		display: block;
		padding: 1rem 0;
		@media only screen and (min-width: 768px) {
			height: 6rem;
			padding: 1.75rem 0;
		}
	}
`;

const Links = styled.div`
	display: flex;
	flex-direction: column;
	/* padding: 1rem 0 1.5rem 0; */
	justify-content: center;

	p {
		font-family: ${common.baseFont};
		font-size: 1.5rem;
		margin: 0;

		a {
			color: ${common.spergelRed};
			text-decoration: none;
			font-weight: 600;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	.mobile {
		padding-right: 0.5rem;

		svg path {
			fill: ${common.spergelRed};
			transition: fill 300ms;
		}

		&:hover {
			svg path {
				fill: ${common.lightGrey};
			}
		}
		

		@media (min-width: 768px) {
			display: none;
		}
	}

	.desktop {
		@media (max-width: 767px) {
			display: none;
		}
	}
`;
